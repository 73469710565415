<template>

  <div
    :class="className"
    :style="{height:height,width:width}"
  />
</template>

<script>
import * as echarts from 'echarts';
require('echarts/theme/macarons') // echarts theme
// import { debounce } from '@/utils'

export default {
  props: {
    className: {
      type: String,
      default: 'chart'
    },
    width: {
      type: String,
      default: '100%'
    },
    height: {
      type: String,
      default: '41vh'
    },
    autoResize: {
      type: Boolean,
      default: true
    },
    chartData: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      chart: null
    }
  },
  watch: {
    chartData: {
      deep: true,
      handler (val) {
        this.setOptions(val)
      }
    },
    title: {
      deep: true,
      handler (val) {
        this.setOptions(this.chartData, val)
      }
    }
  },
  mounted () {
    this.initChart()
    if (this.autoResize) {
      // this.__resizeHandler = debounce(() => {
      //   if (this.chart) {
      //     this.chart.resize()
      //   }
      // }, 100)
      window.addEventListener('resize', this.__resizeHandler)
    }

    // 监听侧边栏的变化
    // const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    // sidebarElm.addEventListener('transitionend', this.sidebarResizeHandler)
  },
  beforeDestroy () {
    if (!this.chart) {
      return
    }
    if (this.autoResize) {
      window.removeEventListener('resize', this.__resizeHandler)
    }

    const sidebarElm = document.getElementsByClassName('sidebar-container')[0]
    sidebarElm.removeEventListener('transitionend', this.sidebarResizeHandler)

    this.chart.dispose()
    this.chart = null
  },
  methods: {
    sidebarResizeHandler (e) {
      if (e.propertyName === 'width') {
        this.__resizeHandler()
      }
    },
    setOptions ({ expectedData, actualData } = {}, title) {
      this.chart.setOption({
        title: {
          text: title,
          textStyle: {
            color: '#3E3E5B',
            fontWeight: 'bold'
          }
        },
        backgroundColor: 'rgba(255,255,255,0)',
        xAxis: {
          data: this.chartData.timeData,
          // data: [1, 2, 3, 4, 5, 6, 7, 8],
          boundaryGap: true,
          axisTick: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#306268'
            }
          },
          axisLabel: {
            color: '#A8B1AF'
          }
        },
        grid: {
          left: 30,
          right: 30,
          bottom: 20,
          top: 20,
          containLabel: true
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          padding: [5, 10]
        },
        yAxis: [
          {
            type: 'value',
            name: '',
            axisLabel: {
              formatter: '{value}'
            },
            axisLine: {
              show: false
            },
            splitLine: {
              lineStyle: {
                color: 'rgba(48, 98, 104,0.2)',
                width: 1
              }
            }
          }
        ],

        series: [{
          name: '收益',
          itemStyle: {
            normal: {
              color: '#00CBFF',
              lineStyle: {
                color: '#00CBFF',
                width: 2
              }
            }
          },
          smooth: true,
          type: 'line',
          data: this.chartData.totalMoneyData,
          // data: [100, 200, 300, 200, 350, 400, 300, 260],
          animationDuration: 2800,
          animationEasing: 'cubicInOut',
          areaStyle: {
            opacity: 0.29,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(0, 203, 255, 1)'
            }, {
              offset: 1,
              color: 'rgba(2, 16, 47, 1)'
            }])
          }
        }]
      })
    },
    initChart () {
      this.chart = echarts.init(this.$el, 'macarons')
      this.setOptions(this.chartData, this.title)
    }
  }
}
</script>
